import React, { useReducer, forwardRef, useCallback, useRef, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import {
    Checkbox as PrestyledCheckbox,
    Auth,
    SignInMethod,
    LoadingIndicator,
} from '@streamr/streamr-layout'
import ReactMarkdown from 'react-markdown'
import { Medium, MqTablet, MqDesktop, Regular, Semibold } from 'streamr-ui/css/consts'
import MigrationModal from './MigrationModal'
import MigrationProgress from './MigrationProgress'
import services from './services'
import miroIcon from './assets/MiroIcon.svg'
import playButtonIcon from './assets/PlayButtonIcon.svg'
import MetamaskAdapter from '~utils/MetamaskAdapter'
import WalletConnectAdapter from '~utils/WalletConnectAdapter'
import AnotherDimension from '~shared/AnotherDimension'
import { Headline, Paragraph } from '~/components/DataFund/Hero'
import Layout from '~shared/Layout'
import Spacer from '~shared/Spacer'
import ProgressCounter from '~shared/ProgressCounter'
import useIsMounted from '~hooks/useIsMounted'
import ScrollSuppressor from '~shared/ScrollSuppressor'
import useMetamaskProvider from '~hooks/useMetamaskProvider'
import { MAINNET_CHAIN_ID, XDAI_CHAIN_ID } from '~utils/ethereum'
import { SecondaryButton, ButtonSize, Link } from 'streamr-ui'

function P(props) {
    return <Paragraph {...props} $appear />
}

function Md({ children, inline, paragraph = P }) {
    return (
        <ReactMarkdown
            components={{
                p: inline ? Fragment : paragraph,
            }}
        >
            {children}
        </ReactMarkdown>
    )
}

const { wallets, markets, exchanges } = services

const Container = styled.div``

const Subheading = styled.h2``

const TopSubheading = styled(Subheading)``

const ExternalServicesWrapper = styled.div``

const UpdatedAt = styled.div``

const VideoWrapper = styled.div``

const Video = styled.iframe``

const CheckboxLabel = styled.label``

const ConditionsWrapper = styled.div``

const HelpButtonsWrapper = styled.div``

const Checkbox = styled(PrestyledCheckbox)``

const Services = styled.div``

const Service = styled.div`
    color: #323232;

    ${({ heading }) =>
        !heading &&
        css`
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);

            > div + div {
                color: #0324ff;
            }
        `}
`

function serviceSorter(a, b) {
    if (a.status === b.status) {
        return 0
    }

    if (['Migrated', 'Correct'].indexOf(a.status) !== -1) {
        return -1
    }

    return 1
}

const ServiceListAttrs = ({ entityColumn, statusColumn = 'Status', items = [] }) => ({
    children: (
        <>
            <Service heading $appear>
                <div>
                    <strong>{entityColumn}</strong>
                </div>
                <div>
                    <strong>{statusColumn}</strong>
                </div>
            </Service>
            {items.length ? (
                items.sort(serviceSorter).map(({ name, pair, status = pair, url }) => (
                    <Service key={`${name}-${status}`} $appear>
                        <div>{name}</div>
                        <div>
                            {url ? (
                                <Link href={url} blank>
                                    {status}
                                </Link>
                            ) : (
                                <span>{status}</span>
                            )}
                        </div>
                    </Service>
                ))
            ) : (
                <Service heading>
                    <div>No items.</div>
                    <div />
                </Service>
            )}
        </>
    ),
})

const ServiceList = styled.div.attrs(ServiceListAttrs)``

const UnstyledHelpButton = ({ children, icon, ...props }) => (
    <a {...props} target="_blank" rel="nofollow noopener noreferrer">
        <span>{children}</span>
        <img src={icon} alt="" />
    </a>
)

const HelpButton = styled(UnstyledHelpButton)`
    text-decoration: none;
    border: 0;
    margin: 0;
    padding: 10px 16px;
    box-sizing: border-box;
    min-height: 64px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    border-radius: 8px;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-weight: ${Medium};
    font-size: 14px;
    line-height: 20px;

    > *:first-child {
        flex: 1;
        margin-right: 20px;
    }

    :link,
    :visited,
    :focus,
    :hover,
    :active {
        color: #0324ff;
    }

    ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        transition: opacity 300ms ease-in-out;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
    }

    :hover {
        ::after {
            opacity: 1;
        }
    }

    @media ${MqDesktop} {
        padding: 10px 32px;
    }
`

const ConnectWalletFooter = styled.div`
    color: #525252;
    height: 32px;
    line-height: 32px;

    button {
        float: right;
    }
`

const ConnectWallet = styled.div`
    h3 {
        color: #0c009a;
        font-size: 1rem;
        font-weight: ${Semibold};
        margin: 0;
        line-height: 4rem;
    }

    ${Auth.Panel} {
        margin-top: -1px;
        overflow: hidden;
    }

    ${Auth.PanelRow} {
        position: relative;
        height: auto;
        padding: 0;

        button {
            margin: 0;
            height: 64px;
            padding: 0 32px;
            border-radius: 0;
        }
    }

    ${SignInMethod}[disabled] {
        opacity: 0.5;
    }

    ${LoadingIndicator} {
        position: absolute;
        top: -1px;
    }

    ${Auth.PanelRow}:first-child ${LoadingIndicator} {
        top: 0;
    }

    ${Auth.PanelRow} + ${Auth.PanelRow} {
        border-top: 1px solid #f3f3f3;
    }

    ${ConnectWalletFooter} {
        margin-top: 1.5rem;
    }
`

const NormalSignMethodTheme = {
    color: '#525252',
    background: 'transparent',
    hoverBackground: '#fcfcfc',
    fontSize: 16,
}

const METAMASK = 'metamask'
const WALLET_CONNECT = 'walletConnect'
const WALLET_CONNECT_XDAI = 'walletConnectXdai'

const connectionMethods = [
    {
        id: METAMASK,
        title: 'Metamask',
        icon: <SignInMethod.Icon.Metamask />,
    },
    {
        id: WALLET_CONNECT,
        title: 'WalletConnect (Ethereum)',
        icon: <SignInMethod.Icon.WalletConnect />,
    },
    {
        id: WALLET_CONNECT_XDAI,
        title: 'WalletConnect (xDai)',
        icon: <SignInMethod.Icon.WalletConnect />,
    },
]

const Cta = forwardRef(function Cta({ connect, cancel, connecting, method, error, style }, ref) {
    const metamaskProvider = useMetamaskProvider()
    const [termsAccepted, toggleTerms] = useReducer((x) => !x, false)

    const allDisabled = !!(connecting || !termsAccepted)
    const metamaskDisabled = allDisabled || !metamaskProvider

    return (
        <div style={style} ref={ref}>
            <HelpButtonsWrapper>
                <HelpButton
                    icon={miroIcon}
                    href="https://miro.com/app/board/o9J_ly04_q4=/?invite_link_id=88784446730"
                >
                    Discover Streamr migration Decision tree
                </HelpButton>
                <HelpButton
                    icon={playButtonIcon}
                    href="https://www.youtube.com/watch?v=QV0xPG2VNNU"
                >
                    Watch to know how to migrate Streamr DATA tokens directly from MyEtherWallet
                </HelpButton>
            </HelpButtonsWrapper>
            <ConditionsWrapper>
                <CheckboxLabel>
                    <Checkbox value={termsAccepted} onChange={toggleTerms} />
                    <span>
                        <Md inline>
                            I have read and agree to the [terms and
                            conditions](https://streamr-public.s3.amazonaws.com/migration-terms-and-conditions.pdf)
                        </Md>
                    </span>
                </CheckboxLabel>
                <ConnectWallet>
                    <h3>Connect a wallet</h3>
                    <Auth.Panel>
                        {connectionMethods.map(({ id, title, icon }) => (
                            <Auth.PanelRow key={id}>
                                <LoadingIndicator loading={method === id && connecting} />
                                <SignInMethod
                                    onClick={() => connect(id)}
                                    data-active-method={
                                        !metamaskDisabled && !connecting && method === id
                                    }
                                    theme={
                                        !!error && !connecting && method === id
                                            ? SignInMethod.themes.Error
                                            : NormalSignMethodTheme
                                    }
                                    disabled={metamaskDisabled}
                                >
                                    <SignInMethod.Title>
                                        {method === id && !!connecting && 'Connecting...'}
                                        {!!error &&
                                            method === id &&
                                            !connecting &&
                                            `Couldn't connect to ${title}`}
                                        {(method !== id || (!connecting && !error)) && title}
                                    </SignInMethod.Title>
                                    <SignInMethod.Icon>{icon}</SignInMethod.Icon>
                                </SignInMethod>
                            </Auth.PanelRow>
                        ))}
                    </Auth.Panel>
                    <ConnectWalletFooter>
                        {!error && !connecting && (
                            <span>
                                Need an Ethereum wallet?{' '}
                                <Link href="https://ethereum.org/en/wallets/" blank>
                                    Learn more here
                                </Link>
                            </span>
                        )}
                        {!!connecting && (
                            <SecondaryButton size={ButtonSize.Mole} onClick={() => void cancel()}>
                                Cancel
                            </SecondaryButton>
                        )}
                        {!!error && !connecting && (
                            <SecondaryButton
                                size={ButtonSize.Mole}
                                onClick={() => void connect(method)}
                                disabled={allDisabled}
                            >
                                Try again
                            </SecondaryButton>
                        )}
                    </ConnectWalletFooter>
                </ConnectWallet>
            </ConditionsWrapper>
        </div>
    )
})

const reducer = (state, action) => {
    switch (action.type) {
        case 'start':
            return {
                ...state,
                method: action.method,
                connecting: true,
                tries: state.tries + 1,
            }

        case 'setAdapter': {
            return {
                ...state,
                adapter: action.adapter,
            }
        }

        case 'connected':
            return {
                ...state,
                connecting: false,
                error: undefined,
            }

        case 'error':
            return {
                ...state,
                connecting: false,
                error: action.error,
                adapter: undefined,
            }

        case 'lockScroll': {
            return {
                ...state,
                lockScroll: true,
            }
        }

        case 'enableScroll': {
            return {
                ...state,
                lockScroll: false,
            }
        }

        case 'updateMigrationProgress': {
            return {
                ...state,
                migrationProgressCache: state.migrationProgressCache + 1,
            }
        }

        default:
            break
    }

    return state
}

const UnstyledTokenMigration = (props) => {
    const isMounted = useIsMounted()
    const [
        { tries, method, connecting, error, adapter, lockScroll, migrationProgressCache },
        dispatch,
    ] = useReducer(reducer, {
        tries: 0,
        method: undefined,
        connecting: false,
        error: undefined,
        adapter: undefined,
        lockScroll: false,
        migrationProgressCache: 0,
    })
    const cancelPromiseRef = useRef(undefined)

    const cancel = useCallback(() => {
        if (cancelPromiseRef.current) {
            cancelPromiseRef.current.reject(new Error('User cancelled action'))
            cancelPromiseRef.current = undefined
        }
    }, [])

    const connect = useCallback(
        async (nextMethod) => {
            dispatch({
                type: 'start',
                method: nextMethod,
            })
        },
        [dispatch],
    )

    useEffect(() => {
        if (!method) {
            return undefined
        }

        const connector = () => {
            if (method === METAMASK) {
                return new MetamaskAdapter()
            }

            if (method === WALLET_CONNECT) {
                return new WalletConnectAdapter({
                    chainId: MAINNET_CHAIN_ID,
                })
            }

            if (method === WALLET_CONNECT_XDAI) {
                return new WalletConnectAdapter({
                    chainId: XDAI_CHAIN_ID,
                })
            }

            throw new Error('Unknow method')
        }

        cancel()

        const cancelPromise = new Promise((resolve, reject) => {
            cancelPromiseRef.current = {
                resolve,
                reject,
            }
        })

        let providerAdapter = connector()

        dispatch({
            type: 'setAdapter',
            adapter: providerAdapter,
        })

        Promise.race([providerAdapter.connect(), cancelPromise])
            .then(() => {
                if (isMounted()) {
                    dispatch({
                        type: 'connected',
                    })
                }
                cancelPromiseRef.current = undefined

                return undefined
            })
            .catch((e) => {
                console.error('connect', e)

                if (isMounted()) {
                    dispatch({
                        type: 'error',
                        error: e.message,
                    })
                }

                cancelPromiseRef.current = undefined
            })

        return () => {
            providerAdapter = null
        }
    }, [method, tries, cancel, isMounted])

    useEffect(() => {
        if (!adapter) {
            dispatch({
                type: 'enableScroll',
            })
            return undefined
        }

        const timeout = setTimeout(() => {
            if (isMounted()) {
                dispatch({
                    type: 'lockScroll',
                })
            }
        }, 500)

        return () => {
            clearTimeout(timeout)
            adapter.disconnect()
        }
    }, [adapter, dispatch, isMounted])

    const close = useCallback(() => {
        dispatch({
            type: 'updateMigrationProgress',
        })
        dispatch({
            type: 'setAdapter',
            adapter: undefined,
        })
    }, [dispatch])

    return (
        <Layout {...props}>
            <ScrollSuppressor supress={!!lockScroll} />
            <AnotherDimension>
                <MigrationModal
                    close={close}
                    shown={!!adapter && adapter.isConnected()}
                    adapter={adapter}
                />
            </AnotherDimension>
            <Container>
                <Spacer head="64px,104px,232px" tail="32px,,">
                    <Headline as="h1" $appear>
                        Token Migration
                    </Headline>
                </Spacer>
                <Md>
                    With the passing of **SIP-1**, the Streamr community has decided to upgrade the
                    DATA token to allow the token supply to be increased based on governance
                    decisions, and modernise some of the token features. Both the old and new tokens
                    will coexist during the transition period. The old token has been renamed to
                    XDATA, and the new token uses the symbol DATA. We are in contact with major
                    exchanges and the team is supporting them through the migration process. [Learn
                    more](https://blog.streamr.network/p/b9794c09-202d-4bd9-b9b2-34e5d8f604e8/)
                    about the migration.
                </Md>
                <Spacer head="48px,64px," tail="64px,88px,144px">
                    <MigrationProgress cacheKey={migrationProgressCache} $appear />
                </Spacer>
                <TopSubheading $appear>Migrate now</TopSubheading>
                <Md>
                    You can use the app below to migrate your XDATA to the new DATA. Please watch
                    the video first to ensure you understand the process. Connect your wallet to get
                    started, and if you still need help, come and ask questions in the dedicated
                    [Discord channel](https://discord.gg/gZAm8P7hK8).
                </Md>
                <Spacer head="32px" tail="72px,88px,168px">
                    <VideoWrapper $appearDirection="none">
                        <Video
                            width="560"
                            height="315"
                            src="https://www.youtube-nocookie.com/embed/cNb7wEezdrU"
                            title="DATA Token Migration Tutorial"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    </VideoWrapper>
                    <Cta
                        connect={connect}
                        cancel={cancel}
                        connecting={connecting}
                        method={method}
                        error={error}
                        $appear
                    />
                </Spacer>
            </Container>
            <ExternalServicesWrapper>
                <Spacer head="88px,104px,168px" tail pad>
                    <Container>
                        <TopSubheading $appear>External services status</TopSubheading>
                        <UpdatedAt $appear>
                            <Md inline>
                                **Updated 27th December 2021** Are we missing a service? Drop us an
                                email [support@streamr.network](mailto:support@streamr.network)
                            </Md>
                        </UpdatedAt>
                        <Services>
                            <div>
                                <Subheading $appear>Wallets</Subheading>
                                <p $appear>
                                    Updated wallets display XDATA and DATA correctly. Wallet teams
                                    can not migrate tokens on&nbsp;your behalf.
                                </p>
                            </div>
                            <ServiceList entityColumn="Wallet" items={wallets} />
                        </Services>
                        <Services>
                            <div>
                                <Subheading $appear>DATA Markets</Subheading>
                                <p $appear>
                                    These pairs are confirmed to trade the new DATA&nbsp;token.
                                </p>
                            </div>
                            <ServiceList
                                entityColumn="Exchange"
                                statusColumn="Trading pairs"
                                items={markets}
                            />
                        </Services>
                        <Services>
                            <div>
                                <Subheading $appear>Exchanges</Subheading>
                                <p $appear>
                                    Migrated exchanges support the new DATA token. Pending exchanges
                                    are still trading&nbsp;XDATA.
                                </p>
                                <p $appear>
                                    Do not deposit new DATA to Pending exchanges - your deposit will
                                    be frozen or lost.
                                </p>
                            </div>
                            <ServiceList entityColumn="Exchange" items={exchanges} />
                        </Services>
                    </Container>
                </Spacer>
            </ExternalServicesWrapper>
        </Layout>
    )
}

const TokenMigration = styled(UnstyledTokenMigration)`
    color: #0c009a;

    ${Container} {
        margin: 0 auto;
        padding: 0 32px;
        max-width: 496px;
    }

    ${ProgressCounter.Hub} {
        line-height: 24px;
        /* -7px compensates for Hub's line height of 24px (10px font + 14px extra). */
        margin: 16px 0 -7px;
    }

    ${ProgressCounter.HubItem} a {
        margin-right: 0.75em;
    }

    ${MigrationProgress} a {
        color: #0421ff !important;
    }

    ${Subheading} {
        font-weight: ${Regular};
        font-size: 28px;
        line-height: 40px;
        margin: 0;
    }

    ${TopSubheading} {
        margin: 0 0 48px;
    }

    ${ExternalServicesWrapper} {
        background-color: #fafafa;
    }

    ${UpdatedAt} {
        background-color: #f5f5f5;
        color: #323232;
        border-radius: 8px;
        font-size: 14px;
        line-height: 24px;
        padding: 24px 16px;
    }

    ${UpdatedAt} strong {
        display: block;
    }

    ${VideoWrapper} {
        border-radius: 8px;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
    }

    ${Video} {
        background-color: #f3f3f3;
        border: 0;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    ${UpdatedAt} a {
        color: #0421ff !important;
    }

    ${CheckboxLabel} {
        color: #525252;
        display: flex;
        font-size: 14px;
        line-height: 24px;
    }

    ${CheckboxLabel} a {
        color: inherit !important;
        text-decoration: underline !important;
    }

    ${HelpButtonsWrapper} {
        margin-top: 16px;
    }

    ${HelpButtonsWrapper} > ${HelpButton} + ${HelpButton} {
        margin-top: 16px;
    }

    ${ConditionsWrapper} {
        background-color: #fafafa;
        border-radius: 8px;
        font-size: 14px;
        margin-top: 32px;
        padding: 16px;
    }

    ${ConditionsWrapper} ${Auth.PanelRow} button {
        padding: 0 16px;
    }

    ${Checkbox} {
        margin: 4px 16px 0 0;
    }

    ${Services} {
        margin-top: 112px;
    }

    ${Services} p {
        line-height: 1.5em;
    }

    ${Services} + ${Services} {
        border-top: 1px solid #e7e7e7;
        padding-top: 112px;
    }

    ${Services} > div:first-child {
        margin-bottom: 48px;
    }

    ${Service} {
        display: flex;
        font-size: 14px;
        line-height: 16px;
        padding: 24px;
    }

    ${Service} > div:first-child {
        flex-grow: 1;
    }

    ${Service} > div + div {
        width: 96px;
    }

    ${Service} + ${Service} {
        margin-top: 16px;
    }

    ${Service}:first-child + & {
        margin-top: 0;
    }

    @media ${MqTablet} {
        ${Container} {
            max-width: none;
            width: 496px;
        }

        ${ProgressCounter.Hub} {
            margin: 20px 0 -6px;
        }

        ${Subheading} {
            font-size: 32px;
        }

        ${ExternalServicesWrapper} ${Container} {
            max-width: 648px;
            width: auto;
        }

        ${UpdatedAt} strong {
            display: inline;
        }

        ${Services} {
            display: flex;
            margin-top: 136px;
        }

        ${Services} p {
            max-width: 292px;
        }

        ${Services} > div:first-child {
            flex-grow: 1;
            margin: 0;
            max-width: px;
        }

        ${Services} > div:first-child p {
            padding-right: 32px;
        }

        ${ServiceList} {
            width: 312px;
        }
    }

    @media ${MqDesktop} {
        ${Container} {
            width: 736px;
        }

        ${ProgressCounter.Hub} {
            display: flex;
            line-height: 1em;
            margin: 40px 0 0;
        }

        ${ProgressCounter.HubItem} {
            flex: 0 0 50%;
        }

        ${ConditionsWrapper} {
            padding: 32px;
        }

        ${Subheading} {
            font-size: 40px;
            line-height: 48px;
        }

        ${ExternalServicesWrapper} ${Container} {
            max-width: none;
            width: 736px;
        }

        ${UpdatedAt} {
            font-size: 16px;
            padding: 24px 32px;
        }

        ${ServiceList} {
            width: 344px;
        }

        ${Services} p {
            max-width: 360px;
        }
    }
`

export default TokenMigration
