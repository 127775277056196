import React, { useEffect, useState, forwardRef } from 'react'
import styled from 'styled-components'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { SUPPLY, FORMATTED_SUPPLY } from './config'
import { truncate } from '~utils/streams'
import { OLD_TOKEN_ADDRESS, NEW_TOKEN_ADDRESS, getTokens } from '~utils/ethereum'
import Link from '~shared/Link'
import useIsMounted from '~hooks/useIsMounted'
import ProgressCounter from '~shared/ProgressCounter'

const UnstyledMigrationProgress = forwardRef(function UnstyledMigrationProgress(
    { className, cacheKey, style },
    ref,
) {
    const [progress, setProgress] = useState(0)

    const isMounted = useIsMounted()

    useEffect(() => {
        const { xdataToken } = getTokens()

        if (!xdataToken) {
            return
        }

        ;(async () => {
            try {
                const oldTokenSupplyUpgraded = await xdataToken.totalUpgraded()

                const upgraded = +formatEther(oldTokenSupplyUpgraded)

                const total = +formatEther(parseEther(SUPPLY))

                if (isMounted()) {
                    /**
                     * completion value = how much of the old token supply has been upgraded (i.e. burned)
                     * 0% = none burned, 100% = all burned
                     */
                    setProgress(upgraded / total)
                }
            } catch (e) {
                console.error(e)
            }
        })()
    }, [isMounted, cacheKey])

    return (
        <ProgressCounter
            value={progress}
            supply={FORMATTED_SUPPLY}
            className={className}
            style={style}
            ref={ref}
        >
            <ProgressCounter.Hub>
                <ProgressCounter.HubItem>
                    <Link href={`https://etherscan.io/address/${OLD_TOKEN_ADDRESS}`}>
                        {truncate(OLD_TOKEN_ADDRESS)}
                    </Link>{' '}
                    Old token contract
                </ProgressCounter.HubItem>
                <ProgressCounter.HubItem>
                    <Link href={`https://etherscan.io/address/${NEW_TOKEN_ADDRESS}`}>
                        {truncate(NEW_TOKEN_ADDRESS)}
                    </Link>{' '}
                    New token contract
                </ProgressCounter.HubItem>
            </ProgressCounter.Hub>
        </ProgressCounter>
    )
})

const MigrationProgress = styled(UnstyledMigrationProgress)``

export default MigrationProgress
