import EventEmitter from 'events'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { INFURA_ID, XDAI_CHAIN_ID, XDAI_PROVIDER, toHexString, toNumber } from '~utils/ethereum'

// Prevent WalletConnectProvider from throwing an error outside of a resolved promise,
// this happens when a user selects a network that has no configured RPC url
;((updateRpcUrl, emit) => {
    // eslint-disable-next-line func-names
    WalletConnectProvider.prototype.updateRpcUrl = function (...args) {
        // Temporarily replace event emitter,
        // don't emit an error when there is no RPC url, just log it to console
        // eslint-disable-next-line func-names
        WalletConnectProvider.prototype.emit = function (...emitterArgs) {
            console.error(...emitterArgs)
        }

        // Call the original method normally
        updateRpcUrl.apply(this, args)

        // restore original emitter
        WalletConnectProvider.prototype.emit = emit
    }
})(WalletConnectProvider.prototype.updateRpcUrl, WalletConnectProvider.prototype.emit)

class MetamaskAdapter {
    connector

    provider

    eventEmitter

    connected

    accounts

    chainId

    constructor({ chainId }) {
        this.provider = new WalletConnectProvider({
            infuraId: INFURA_ID,
            rpc: {
                [toNumber(XDAI_CHAIN_ID)]: XDAI_PROVIDER,
            },
            chainId: toNumber(chainId),
        })
        this.emitter = new EventEmitter()

        this.onAccountsChanged = this.onAccountsChanged.bind(this)
        this.onChainChanged = this.onChainChanged.bind(this)
        this.onDisconnect = this.onDisconnect.bind(this)
    }

    async connect() {
        try {
            this.accounts = await this.provider.enable()
            this.chainId = toHexString(this.provider.chainId)

            this.provider.on('accountsChanged', this.onAccountsChanged)
            this.provider.on('chainChanged', this.onChainChanged)
            this.provider.on('error', this.onDisconnect)
            this.provider.on('disconnect', this.onDisconnect)

            this.connected = true
        } catch (e) {
            this.emitter.emit('disconnected')

            throw e
        }

        return this.accounts
    }

    getAccounts() {
        return this.accounts || []
    }

    getChainId() {
        return this.chainId
    }

    isConnected() {
        return this.connected
    }

    getProvider() {
        return this.provider
    }

    // eslint-disable-next-line class-methods-use-this
    canWatchAsset() {
        return false
    }

    // eslint-disable-next-line class-methods-use-this
    canSwitchChain() {
        return false
    }

    on(...args) {
        this.emitter.on(...args)
    }

    off(...args) {
        this.emitter.removeListener(...args)
    }

    onDisconnect() {
        this.emitter.emit('disconnected')
    }

    onAccountsChanged(accounts) {
        this.accounts = accounts

        if (!accounts || accounts.length <= 0) {
            this.emitter.emit('disconnected')
        } else {
            this.emitter.emit('accountsChanged', accounts)
        }
    }

    onChainChanged(chainId) {
        this.chainId = toHexString(chainId)

        this.emitter.emit('chainChanged', this.chainId)
    }

    disconnect() {
        this.connected = false

        if (this.provider) {
            // this.provider.disconnect()
            this.provider.off('accountsChanged', this.onAccountsChanged)
            this.provider.off('chainChanged', this.onChainChanged)
            this.provider.off('error', this.onDisconnect)
            this.provider.off('disconnect', this.onDisconnect)
        }

        this.emitter.removeAllListeners()
    }
}

export default MetamaskAdapter
