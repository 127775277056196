import React, {
    forwardRef,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useReducer,
} from 'react'
import styled from 'styled-components'

const leadingZeroStripper = (str) => str.replace(/^0+(\d)/, (...[, s]) => s)

export const formatWhole = (_str, { stripLeadingZeros = false, expandWhole = true } = {}) => {
    const str = (stripLeadingZeros && _str !== '0' ? leadingZeroStripper(_str) : _str).replace(
        /\s+/g,
        '',
    )

    if (!expandWhole) {
        return str
    }

    return str
        .split('')
        .reverse()
        .join('') // '1234' -> '4321' at this point.
        .replace(/\d{1,3}/g, (s) => ` ${s}`) // '4321' -> '432 1'
        .split('')
        .reverse()
        .join('')
        .trim() // '1 234' at this point
}

const formatAmount = (
    value,
    { stripLeadingZeros = false, decimalsLimit = 18, wholeLimit = 9, expandWhole = true } = {},
) => {
    const [whole, ...decimals] = value.replace(/[^\d.,]+/g, '').split(/[,.]+/)

    const decimal = decimals.join('')

    return `${formatWhole(whole.substr(0, wholeLimit), {
        stripLeadingZeros,
        expandWhole,
    })}${decimals.length ? `.${decimal.substr(0, decimalsLimit)}` : ''}`
}

const writeSelectionRange = (ref, { value, selectionStart: start, selectionEnd: end }) => {
    const start1 = value.substring(0, start).replace(/\s+/g, '').length

    ref.current = [start1, start1 + value.substring(start, end).replace(/\s+/g, '').length]
}

const UnstyledEtherInput = forwardRef(function UnstyledEtherInput(
    {
        decimalsLimit = 18,
        wholeLimit = 9,
        onChange: onChangeProp,
        onKeyDown: onKeyDownProp,
        onSelect: onSelectProp,
        onValueChange,
        readOnly = false,
        value: valueProp = '',
        ...props
    },
    ref,
) {
    const [{ cache, value }, setValue] = useReducer(
        (state, newValue) => ({
            value: typeof newValue === 'undefined' ? state.value : newValue,
            cache: state.cache + 1,
        }),
        {
            cache: -1,
            value: '',
        },
    )

    const selectionRangeRef = useRef([0, 0])

    const moddedRangeRef = useRef(null)

    const setCleanValue = useCallback(
        (val) => {
            const newValue = formatAmount(val, {
                decimalsLimit,
                stripLeadingZeros: true,
                wholeLimit,
                expandWhole: false,
            })

            setValue(newValue)

            if (typeof onValueChange === 'function') {
                onValueChange(newValue)
            }
        },
        [decimalsLimit, onValueChange, wholeLimit],
    )

    const valueRef = useRef(value)

    valueRef.current = value

    const setCleanValueRef = useRef(setCleanValue)

    setCleanValueRef.current = setCleanValue

    useEffect(() => {
        setCleanValueRef.current(valueRef.current)
    }, [decimalsLimit])

    useEffect(() => {
        setCleanValueRef.current(`${valueProp}`)
    }, [valueProp])

    const onChange = (e) => {
        setCleanValue(e.target.value)

        if (typeof onChangeProp === 'function') {
            onChangeProp(e)
        }
    }

    const onSelect = (e) => {
        writeSelectionRange(selectionRangeRef, e.target)

        if (typeof onSelectProp === 'function') {
            onSelectProp(e)
        }
    }

    const inputRef = useRef()

    useLayoutEffect(() => {
        const [start, end] = selectionRangeRef.current

        const range = moddedRangeRef.current || [start, end]

        const { current: input } = ref || inputRef

        if (input) {
            input.setSelectionRange(...range)
        }
    }, [value, cache, ref])

    function dot() {
        const [start, end] = selectionRangeRef.current

        const dotIndex = value.indexOf('.')

        if (dotIndex !== -1 && dotIndex < start) {
            setCleanValue(`${value.substring(0, start).replace('.', '')}.${value.substring(end)}`)
        }
    }

    function backspace() {
        const [start, end] = selectionRangeRef.current

        if (
            start &&
            start === end &&
            ((x) => x > 0 && x % 3 === 0)(value.replace(/\..*/, '').length - start)
        ) {
            setCleanValue(`${value.substring(0, start - 1)}${value.substring(end)}`)
        }
    }

    function zero(e) {
        const [start, end] = selectionRangeRef.current

        if (start === 0) {
            setCleanValue(`0.${value.substring(end)}`)
            e.preventDefault()
        }
    }

    function moveSelectionRange(key) {
        const [start, end] = selectionRangeRef.current

        if (!/^Backspace|\S|\s$/u.test(key)) {
            moddedRangeRef.current = null
            return
        }

        const head = value.substring(0, start)

        const dotIndex = value.indexOf('.')

        const newHead = (() => {
            if (key === 'Backspace' && head && start === end) {
                return head.substring(0, start - 1)
            }

            if (key === '0' && !head) {
                return '0.'
            }

            if (/^\d$/.test(key) && (dotIndex === -1 || start <= dotIndex) && start < wholeLimit) {
                return `${head}${key}`
            }

            if (
                /^\d$/.test(key) &&
                dotIndex !== -1 &&
                start > dotIndex &&
                start - (dotIndex + 1) < decimalsLimit
            ) {
                return `${head}${key}`
            }

            if (/^[.,]$/.test(key)) {
                return `${head.replace(/\./, '')}.`
            }

            return head
        })()

        const tail = value.substring(end)

        const futureValue = formatAmount(`${newHead}${tail}`, {
            decimalsLimit,
            stripLeadingZeros: true,
            wholeLimit,
        })

        const [, match] = futureValue.match(
            new RegExp(`^(([\\d.]\\s*){0,${newHead.length}}).*$`),
        ) || [null, '']

        moddedRangeRef.current = [match.length, match.length]
    }

    const onKeyDown = (e) => {
        if (!readOnly) {
            writeSelectionRange(selectionRangeRef, e.target)
            moveSelectionRange(e.key)

            switch (e.key) {
                case '0':
                    zero(e)
                    break
                case '.':
                case ',':
                    // We want to be able to inject a dot at any point in time, pretty much. There can be only one
                    // though. Gotta clean-up before we add it. Also, commas are dots.
                    dot()
                    break
                case 'Backspace':
                    // Hitting `Backspace` behind a space (`1 <here>234`) normally is a no-op. We have to manually
                    // remove what's in front of the space.
                    // TODO: What about `Del` key (removes characters in front of the cursor)?
                    backspace()
                    break
                default:
                    break
            }
        }

        if (typeof onKeyDownProp === 'function') {
            onKeyDownProp(e)
        }
    }

    return (
        <input
            {...props}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onSelect={onSelect}
            readOnly={readOnly}
            ref={ref || inputRef}
            value={formatAmount(value, {
                stripLeadingZeros: true,
            })}
        />
    )
})

const EtherInput = styled(UnstyledEtherInput)``

export default EtherInput
