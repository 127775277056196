import styled, { css } from 'styled-components'
import { Medium, MqTablet } from 'streamr-ui/css/consts'

const ButtonAttrs = () => ({
    type: 'button',
})

const Button = styled.button.attrs(ButtonAttrs)`
    align-items: center;
    appearance: none;
    border: 0;
    display: flex;
`

export const Box = styled(Button)`
    background-color: #f8f8f8;
    border-radius: 4px;
    font-size: 12px;
    font-weight: ${Medium};
    height: 48px;
    padding: 0 16px;
    white-space: nowrap;
    width: 100%;

    @media ${MqTablet} {
        height: 32px;
        width: auto;
        padding: 0 10px;
    }
`

export const List = styled.ul`
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
    list-style: none;
    margin: 0;
    max-height: 244px;
    overflow: auto;
    padding: 4px 0;
    position: absolute;
    top: 48px;
    transform: translateY(8px);
    z-index: 1;

    ${({ visible }) =>
        !visible &&
        css`
            display: none;
        `}

    @media ${MqTablet} {
        top: 32px;
    }

    > li + li {
        border-top: 1px solid #efefef;
    }
`

export const Item = styled(Button)`
    background-color: #ffffff;
    font-size: 14px;
    height: 64px;
    padding: 0 16px;
    white-space: nowrap;
    width: 100%;

    :hover,
    :focus {
        background-color: #f8f8f8;
    }
`
