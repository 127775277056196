import { useEffect, useState } from 'react'
import useIsMounted from '~hooks/useIsMounted'

export default function useConnectedAccounts(adapter) {
    const [connectedAccounts, setConnectedAccounts] = useState([])

    const isMounted = useIsMounted()

    useEffect(() => {
        if (!adapter) {
            return () => {}
        }

        try {
            const accounts = adapter.getAccounts()

            if (isMounted()) {
                setConnectedAccounts(accounts)
            }
        } catch (e) {
            console.error('useConnectedAccounts', e)
        }

        const onAccountsChanged = (accounts) => {
            if (isMounted()) {
                setConnectedAccounts(accounts)
            }
        }

        adapter.on('accountsChanged', onAccountsChanged)

        return () => {
            adapter.off('accountsChanged', onAccountsChanged)
        }
    }, [adapter, isMounted])

    return connectedAccounts
}
