import { formatMoney } from 'accounting'

export const SUPPLY = '987154514'

export const FORMATTED_SUPPLY = formatMoney(+SUPPLY, {
    symbol: 'DATA',
    format: '%v %s',
    precision: 0,
    thousand: ' ',
})
