import React from 'react'
import TokenMigration from '../components/TokenMigration'
import page from '~utils/page'
import { RESOURCES } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(TokenMigration, {
    highlight: RESOURCES,
    headComponent: (
        <Head
            mediaDescription="Migrate your DATA tokens"
            // eslint-disable-next-line max-len
            description="For Streamr DATA token holders. Learn how to migrate your DATA V1 to the new DATA and view the migration completion status in real time."
        />
    ),
})
