import React, { useEffect, useState, useReducer, useRef, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { formatEther, parseEther } from 'ethers/lib/utils'
import LogoETH from './assets/LogoETH.svg'
import LogoXDAI from './assets/LogoXDAI.svg'
import LogoNoCurrency from './assets/LogoNoCurrency.svg'
import LogoNewToken from './assets/LogoNewToken.svg'
import LogoOldToken from './assets/LogoOldToken.svg'
import useBalances, { ZERO, ONE } from './useBalances'
import useConvert from './useConvert'
import useConnectedAccounts from './useConnectedAccounts'
import {
    Alert,
    Backdrop,
    Balance,
    Button,
    CloseButton,
    ContentWrapper,
    Header,
    Inner,
    Input,
    InputWrapper,
    List,
    ListActionsItem,
    ListAlertItem,
    ListBalanceItem,
    ListItem,
    Outer,
    SelectorBox,
    Selectors,
    SpaceWrapper,
    AlertNotification,
} from './MigrationModal.styled'
import isAmountValid from './isAmountValid'
import formatWei from './formatWei'
import { Tooltip } from 'streamr-ui'
import useChainId from '~/hooks/useChainId'
import useWatchToken from '~hooks/useWatchToken'
import Display from '~shared/Display'
import useIsMounted from '~hooks/useIsMounted'
import useKeyDownCallback from '~hooks/useKeyDownCallback'
import { networks, MAINNET_CHAIN_ID, XDAI_CHAIN_ID } from '~utils/ethereum'

const currencyIcons = {
    [MAINNET_CHAIN_ID]: LogoETH,
    [XDAI_CHAIN_ID]: LogoXDAI,
}

const validOrZeroWei = (amountTokens, balanceWei = ZERO) => {
    const balanceDecimal = formatEther(balanceWei.mod(ONE))

    const amountWei =
        !/^\.?$/.test(amountTokens) && /^\d{0,9}(\.\d{0,18})?$/.test(amountTokens)
            ? parseEther(amountTokens)
            : ZERO

    const amountDecimal = formatEther(amountWei.mod(ONE))

    // If 3 or more digits of the given amount match the first digits of the balance we fill up the rest.
    const finalAmountWei =
        /^0\.\d{3,}$/.test(amountDecimal) && balanceDecimal.indexOf(amountDecimal) === 0
            ? amountWei.sub(amountWei.mod(ONE)).add(balanceWei.mod(ONE))
            : amountWei

    return finalAmountWei
}

const networkIds = new Set(
    Object.keys(networks).filter((chainId) => {
        const { migratable } = networks[chainId]

        return !!migratable
    }),
)

const UnstyledMigrationModal = ({ className, close, shown, adapter }) => {
    const accounts = useConnectedAccounts(adapter)

    const [currentAccount, setCurrentAccount] = useState(accounts[0])
    const [hasDisconnected, setHasDisconnected] = useState(false)

    const { chainId, switchChain, switchPending } = useChainId(adapter)

    useEffect(() => {
        setCurrentAccount((prev) => (accounts.includes(prev) ? prev : accounts[0]))
    }, [accounts])

    useEffect(() => {
        if (!adapter) {
            return undefined
        }

        const onDisconnect = () => {
            setHasDisconnected(true)
        }

        adapter.on('disconnected', onDisconnect)

        return () => {
            adapter.off('disconnected', onDisconnect)
        }
    }, [adapter])

    const [amountTokens, setAmountTokens] = useState('')

    const [balancesCache, touchBalanceCache] = useReducer((x) => x + 1, 0)

    const balances = useBalances({
        address: currentAccount,
        chainId,
        cache: balancesCache,
    })

    const {
        data: dataBalanceWei = ZERO,
        nativeCurrency: nativeCurrencyBalanceWei = ZERO,
        xdata: xdataBalanceWei = ZERO,
    } = balances || {}

    const ready = !!balances

    useKeyDownCallback('Escape', close)

    const onValueChange = (value) => {
        setAmountTokens(value)
    }

    const amountTokensWei = validOrZeroWei(amountTokens, xdataBalanceWei)

    useEffect(() => {
        if (shown) {
            touchBalanceCache()
        }
    }, [shown])

    const isMounted = useIsMounted()

    const { convert, converting } = useConvert({
        adapter,
        address: currentAccount,
        chainId,
        xdataAmountWei: amountTokensWei,
        onComplete: () => {
            if (isMounted()) {
                touchBalanceCache()
            }
        },
    })

    const validChainId = networkIds.has(chainId)

    const noNativeCurrency = /^0(\.0+)?$/.test(formatEther(nativeCurrencyBalanceWei))

    const disabled =
        converting ||
        hasDisconnected ||
        !isAmountValid(amountTokens, xdataBalanceWei) ||
        !validChainId ||
        noNativeCurrency

    const inputRef = useRef()

    const focus = useCallback(() => {
        const { current: input } = inputRef

        if (input) {
            input.focus()

            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.setSelectionRange(
                        inputRef.current.value.length,
                        inputRef.current.value.length,
                    )
                }
            }, 0)
        }
    }, [])

    const needsFocusRef = useRef(false)

    const fillInAvailableBalance = useCallback(() => {
        const with0s = formatEther(xdataBalanceWei).replace(
            /^(\d+)\.(\d+)$/,
            (_, whole, decimals) => `${whole}.${`${decimals}000`.substr(0, 3)}`,
        )
        setAmountTokens(with0s)
        needsFocusRef.current = true
    }, [xdataBalanceWei])

    useEffect(() => {
        if (needsFocusRef.current) {
            focus()
        }
        needsFocusRef.current = false
    }, [amountTokens, focus])

    useEffect(() => {
        fillInAvailableBalance()
    }, [fillInAvailableBalance])

    const onInputKeyDown = (e) => {
        if (e.key === 'Enter' && !disabled) {
            convert()
            return
        }

        if (e.key === 'Tab' && !amountTokens) {
            fillInAvailableBalance()
            e.preventDefault()
        }
    }

    const currency = useMemo(() => {
        const { currency: networkCurrency } = networks[chainId] || {}

        return networkCurrency || 'N/A'
    }, [chainId])

    const currencyIcon = useMemo(() => currencyIcons[chainId] || LogoNoCurrency, [chainId])

    const error = (() => {
        if (!validChainId) {
            return 'Please switch to a supported chain'
        }

        if (hasDisconnected) {
            return 'Wallet not connected'
        }

        if (ready && noNativeCurrency) {
            return `Not enough ${currency} for gas`
        }

        return null
    })()

    const [canWatchToken, watchToken] = useWatchToken({
        adapter,
        chainId,
    })

    return (
        <div className={className}>
            <Backdrop />
            <Display as={CloseButton} mobile="none" tablet onClick={close} />
            <ContentWrapper>
                <Outer>
                    <SpaceWrapper>
                        <Inner>
                            {!!error && (
                                <Display as={AlertNotification} mobile="none" tablet="flex">
                                    <Alert>{error}</Alert>
                                </Display>
                            )}
                            <Header>
                                <h1>Migrate your tokens</h1>
                                <Display
                                    as={Selectors}
                                    currentAccount={!hasDisconnected && currentAccount}
                                    chainId={chainId}
                                    mobile="none"
                                    tablet="flex"
                                    onSelect={switchChain}
                                    disabled={
                                        switchPending || converting || !adapter.canSwitchChain()
                                    }
                                />
                                <Display tablet="none">
                                    <SelectorBox onClick={close} />
                                </Display>
                            </Header>
                            <Display
                                as={Selectors}
                                currentAccount={!hasDisconnected && currentAccount}
                                chainId={chainId}
                                tablet="none"
                                onSelect={switchChain}
                                disabled={switchPending || converting || !adapter.canSwitchChain()}
                            />
                            <List>
                                <ListBalanceItem
                                    title="Your wallet balance"
                                    backgroundColor={error ? '#fdf8f8' : '#edfcea'}
                                >
                                    <Balance
                                        icon={<img src={LogoOldToken} alt="XDATA" />}
                                        name="XDATA"
                                    >
                                        {formatWei(xdataBalanceWei)}
                                    </Balance>
                                    <Balance
                                        icon={<img src={LogoNewToken} alt="DATA" />}
                                        name="DATA"
                                    >
                                        {formatWei(dataBalanceWei)}
                                    </Balance>
                                    <Balance
                                        icon={<img src={currencyIcon} alt={currency} />}
                                        name={currency}
                                    >
                                        {formatWei(nativeCurrencyBalanceWei)}
                                    </Balance>
                                </ListBalanceItem>
                                <ListItem
                                    title="Amount to migrate"
                                    headerLink={
                                        <button type="button" onClick={fillInAvailableBalance}>
                                            Use entire balance
                                        </button>
                                    }
                                    actions={
                                        <Button
                                            disabled={disabled}
                                            onClick={convert}
                                            waiting={converting}
                                        >
                                            {converting ? 'Migrating…' : 'Migrate tokens'}
                                        </Button>
                                    }
                                >
                                    <Balance
                                        icon={<img src={LogoOldToken} alt="XDATA" />}
                                        name="XDATA"
                                    >
                                        Old token
                                    </Balance>
                                    <InputWrapper>
                                        <Input
                                            decimalsLimit={3}
                                            disabled={!ready || converting}
                                            onKeyDown={onInputKeyDown}
                                            onValueChange={onValueChange}
                                            placeholder={formatWei(xdataBalanceWei)}
                                            ref={inputRef}
                                            value={amountTokens}
                                        />
                                    </InputWrapper>
                                </ListItem>
                                <ListItem
                                    title="You receive"
                                    invalid={noNativeCurrency}
                                    actions={
                                        <Button
                                            onClick={watchToken}
                                            disabled={
                                                converting ||
                                                hasDisconnected ||
                                                !validChainId ||
                                                !canWatchToken
                                            }
                                        >
                                            Add to wallet
                                        </Button>
                                    }
                                >
                                    <Balance
                                        icon={<img src={LogoNewToken} alt="DATA" />}
                                        name="DATA"
                                    >
                                        New token
                                    </Balance>
                                    <InputWrapper>
                                        <Tooltip
                                            value={formatWei(amountTokensWei, {
                                                decimalsLimit: 18,
                                            })}
                                        >
                                            {formatWei(amountTokensWei)}
                                        </Tooltip>
                                    </InputWrapper>
                                </ListItem>
                                <Display as={ListAlertItem} tablet="none">
                                    <Alert invisible={!error}>{error}</Alert>
                                </Display>
                                <Display as={ListActionsItem} tablet="none">
                                    <Button
                                        disabled={disabled}
                                        onClick={convert}
                                        waiting={converting}
                                        size="big"
                                    >
                                        {converting ? 'Migrating…' : 'Migrate tokens'}
                                    </Button>
                                </Display>
                            </List>
                        </Inner>
                    </SpaceWrapper>
                </Outer>
            </ContentWrapper>
        </div>
    )
}

const MigrationModal = styled(UnstyledMigrationModal)`
    color: #0c009a;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
    transition: 0.3s;
    transition-property: visibility, opacity;
    transition-delay: 0.3s, 0s;

    ${Inner} {
        transform: translateY(5%);
        transition: 0.3s transform;
    }

    ${({ shown }) =>
        !!shown &&
        css`
            opacity: 1;
            pointer-events: auto;
            transition-delay: 0s;
            transition-duration: 0.1s;
            visibility: visible;

            ${Inner} {
                transform: translateY(0);
                transition-duration: 0.1s;
            }
        `}
`

const ModalWrap = ({ shown, adapter, ...props }) => {
    if (!shown || !adapter) {
        return null
    }

    return <MigrationModal {...props} adapter={adapter} shown />
}

export default ModalWrap
