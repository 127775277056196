import React from 'react'
import styled, { css } from 'styled-components'
import { Button as PrestyledButton } from '@streamr/streamr-layout'
import { Medium, MqTablet } from 'streamr-ui/css/consts'
import AlertIcon from './assets/AlertIcon.svg'
import Selector from './Selector'
import Address from './Address'
import { Box as PrestyledBox } from './Selector.styled'
import EtherInput from '~shared/EtherInput'
import { Xxx as UnstyledXxx } from '~shared/Glyph'
import Display from '~shared/Display'
import { networks as predefinedNetworks } from '~utils/ethereum'

export const Backdrop = styled.div`
    background-color: #ffffff;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
`

export const Outer = styled.div`
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    min-height: 100%;
    overflow: auto;
    padding: 0;
    pointer-events: none;
    position: relative;
    width: 100%;

    @media ${MqTablet} {
        align-items: center;
        padding: 0 80px;
    }
`

export const ContentWrapper = styled.div`
    width: 100%;
    overflow: auto;
    height: 100%;
`

export const SpaceWrapper = styled.div`
    padding: 0;
    width: 100%;

    @media ${MqTablet} {
        padding: 80px 0;
    }
`

export const Inner = styled.div`
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 736px;
    pointer-events: auto;
    padding: 56px 24px 40px;
    width: 100%;
    position: relative;

    @media ${MqTablet} {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        padding: 56px 48px 64px;
    }
`

const Xxx = styled(UnstyledXxx)``

const CloseButtonAttrs = () => ({
    children: <Xxx />,
    type: 'button',
})

export const CloseButton = styled.button.attrs(CloseButtonAttrs)`
    appearance: none;
    background: none;
    border: 0;
    box-sizing: content-box;
    padding: 1.75rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 1.5rem;

    svg {
        display: block;
    }
`

const TokenAttrs = ({ name, children }) => ({
    children: (
        <>
            <h2>{name}</h2>
            <p>
                &zwnj;
                <span>{children}</span>
            </p>
        </>
    ),
})

const Token = styled.div.attrs(TokenAttrs)`
    flex-shrink: 0;
    margin-left: 16px;
    width: 88px;

    h2 {
        font-size: 18px;
        font-weight: ${Medium};
        line-height: normal;
        margin: 0;
    }

    h2 + p {
        color: #a3a3a3;
        font-size: 12px;
        line-height: normal;
        margin: 0;
    }

    p {
        position: relative;
    }

    span {
        position: absolute;
        white-space: nowrap;
    }

    @media ${MqTablet} {
        margin-left: 24px;
    }
`

export const Input = styled(EtherInput)`
    appearance: none;
    background-color: #fafafa;
    border: 0;
    color: inherit;
    font: inherit;
    font-size: 18px;
    height: 40px;
    outline: 0 !important;
    padding: 0 8px;
    text-align: right;
    border-radius: 8px;
    transition: 180ms background-color ease-in-out;

    ::placeholder {
        color: rgba(12, 0, 154, 0.5);
    }

    :focus-within {
        background-color: #e2f7f9;
    }

    @media ${MqTablet} {
        font-size: 24px;
        text-align: center;
    }
`

const InputWrapperInner = styled.div`
    position: absolute;
    transform: translateY(-50%);
    width: 100%;
    box-sizing: border-box;
    padding-right: 8px;

    ${Input} {
        width: 100%;
        box-sizing: border-box;
        margin-right: -8px;
    }
`

const InputWrapperAttrs = ({ children }) => ({
    children: <InputWrapperInner>{children}</InputWrapperInner>,
})

export const InputWrapper = styled.div.attrs(InputWrapperAttrs)`
    flex: 1;
    position: relative;
    text-align: right;

    @media ${MqTablet} {
        text-align: center;
    }
`

export const List = styled.ul`
    list-style: none;
    margin: 40px 0 0;
    padding: 0;

    li + li {
        margin-top: 20px;
    }

    @media ${MqTablet} {
        margin-top: 56px;
    }
`

const ListItemInner = styled.div`
    background-color: #fafafa;
    border-radius: 8px;
    box-sizing: border-box;
    height: 88px;
    margin-top: 6px;
    padding: 12px 16px;
    position: relative;

    @media ${MqTablet} {
        padding: 24px;
    }
`

const ListItemContent = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`

const ListItemHeader = styled.strong`
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: normal;

    > span {
        color: #323232;
        flex-grow: 1;
    }

    button {
        appearance: none;
        background: none;
        border: 0;
        color: #0324ff;
        display: inline;
        font: inherit;
        height: auto;
        line-height: inherit;
        margin: 0;
        outline: 0 !important;
        padding: 0;
        width: auto;
    }

    button:hover,
    button:focus {
        text-decoration: underline;
    }
`

const Actions = styled.div`
    padding: 0 32px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`

const UnstyledListItem = ({ actions, children, className, headerLink, title }) => (
    <li className={className}>
        <ListItemHeader>
            <span>{title}</span>
            <div>{headerLink}</div>
        </ListItemHeader>
        <ListItemInner>
            <ListItemContent>{children}</ListItemContent>
            {!!actions && (
                <Display as={Actions} mobile="none" tablet>
                    {actions}
                </Display>
            )}
        </ListItemInner>
    </li>
)

export const ListItem = styled(UnstyledListItem)`
    ${({ actions }) =>
        !!actions &&
        css`
            @media ${MqTablet} {
                ${ListItemContent} {
                    margin-right: 192px;
                }
            }
        `}
`

const BalanceAttrs = ({ name, icon, children }) => ({
    children: (
        <>
            {icon}
            <Token name={name}>{children}</Token>
        </>
    ),
})

export const Balance = styled.div.attrs(BalanceAttrs)`
    align-items: center;
    display: flex;

    img {
        display: block;
        height: 40px;
        transition: 0.2s width, 0.2s height;
        width: 40px;
    }

    @media ${MqTablet} {
        img {
            height: 32px;
            width: 32px;
        }
    }

    @media (min-width: 810px) {
        img {
            height: 40px;
            width: 40px;
        }
    }
`

export const ListBalanceItem = styled(ListItem)`
    ${ListItemContent} {
        justify-content: space-between;
    }

    ${Balance} img {
        display: none;
    }

    ${Token} {
        margin-left: 0;
    }

    ${({ backgroundColor }) =>
        !!backgroundColor &&
        css`
            ${ListItemInner} {
                background-color: ${backgroundColor} !important;
            }
        `}

    @media ${MqTablet} {
        ${Token} {
            margin-left: 24px;
        }

        ${Balance} img {
            display: block;
        }
    }
`

const AlertAttrs = ({ children }) => ({
    children: (
        <>
            <img src={AlertIcon} alt="" />
            <span>{children}</span>
        </>
    ),
})

export const Alert = styled.div.attrs(AlertAttrs)`
    align-items: center;
    color: #323232;
    display: flex;
    font-size: 14px;
    font-weight: ${Medium};

    ${({ invisible }) =>
        !!invisible &&
        css`
            visibility: hidden;
        `}

    img {
        display: block;
        margin-right: 8px;
        height: 17px;
        width: 17px;
    }

    span {
        white-space: nowrap;
    }
`

export const Button = styled(PrestyledButton)`
    min-width: 140px;
    padding-left: 20px;
    padding-right: 20px;
    pointer-events: all;
`

export const Header = styled.div`
    align-items: center;
    display: flex;
    height: 32px;

    h1 {
        flex-grow: 1;
        font-size: 20px;
        font-weight: ${Medium};
        line-height: normal;
        margin: 0 16px 0 0;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ${Alert} {
        margin-right: 16px;
    }

    @media ${MqTablet} {
        h1 {
            font-size: 24px;
        }
    }
`

export const ListActionsItem = styled.li`
    ${Button} {
        height: 64px;
        width: 100%;

        /* prevents spinner from expanding to 100% width */
        > *:not(:first-child) {
            width: auto;
        }
    }
`

export const ListAlertItem = styled.li`
    &,
    & + li {
        margin-top: 12px !important;
    }

    ${Alert} {
        justify-content: center;
    }
`

export const SelectorBox = styled(PrestyledBox).attrs(CloseButtonAttrs)`
    height: 32px;
    padding: 8px;
    width: 32px;
`

const networks = Object.keys(predefinedNetworks).reduce((allowedNetworks, chainId) => {
    const { name, migratable } = predefinedNetworks[chainId]

    if (!migratable) {
        return allowedNetworks
    }

    return [
        ...allowedNetworks,
        {
            id: chainId,
            title: name,
        },
    ]
}, [])

const SelectorsAttrs = ({ currentAccount, chainId, onSelect, disabled }) => ({
    children: (
        <>
            <Selector items={networks} selected={chainId} onSelect={onSelect} disabled={disabled} />
            <PrestyledBox>
                {!currentAccount && (
                    <>
                        <img src={AlertIcon} alt="" />
                        <span>Not connected</span>
                    </>
                )}
                {!!currentAccount && <Address value={currentAccount} />}
            </PrestyledBox>
        </>
    ),
})

export const Selectors = styled.div.attrs(SelectorsAttrs)`
    align-items: center;
    display: flex;
    margin-top: 40px;
    position: relative;

    > * {
        flex-basis: 50%;
    }

    > * + * {
        margin-left: 16px;
    }

    ${Selector} {
        position: initial;
    }

    ${PrestyledBox} {
        justify-content: center;

        > img {
            display: block;
            margin-right: 8px;
            height: 16px;
            width: 16px;
        }

        > span {
            white-space: nowrap;
            line-height: 20px;
        }
    }

    @media ${MqTablet} {
        margin-top: 0;

        ${Selector},
        ${PrestyledBox} {
            min-width: 122px;
        }
    }
`

export const AlertNotification = styled.div`
    position: absolute;
    left: 0;
    top: -72px;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    background-color: #fafafa;
    border-radius: 6px;
    padding: 0 16px;
    align-items: center;
`
