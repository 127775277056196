import { useEffect, useRef } from 'react'

export default function useKeyDownCallback(key, callback) {
    const callbackRef = useRef()

    callbackRef.current = callback

    useEffect(() => {
        const onKeyDown = ({ key: _key }) => {
            const { current: cb } = callbackRef
            if (key === _key && typeof cb === 'function') {
                cb()
            }
        }

        if (typeof window === 'undefined') {
            return () => {}
        }

        window.addEventListener('keydown', onKeyDown)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
        }
    }, [key])
}
