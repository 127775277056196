import { useCallback, useEffect, useState, useRef } from 'react'
import { providers } from 'ethers'
import useIsMounted from '~hooks/useIsMounted'
import { getTokens, XDAI_CHAIN_ID } from '~utils/ethereum'

async function migrateXdaiTokens({ chainId, provider, amount }) {
    const { xdataToken, migrator } = getTokens({
        chainId,
    })

    const signer = new providers.Web3Provider(provider).getSigner()

    const approveTx = await xdataToken.connect(signer).approve(migrator.address, amount)
    await approveTx.wait()
    const migrateTx = await migrator.connect(signer).swap(amount)
    await migrateTx.wait()
}

async function migrateMainnetTokens({ chainId, provider, amount }) {
    const { xdataToken } = getTokens({
        chainId,
    })

    const signer = new providers.Web3Provider(provider).getSigner()

    const tx = await xdataToken.connect(signer).upgrade(amount)

    await tx.wait()
}

async function migrate({ chainId, address, provider, amount }) {
    const { xdataToken } = getTokens({
        chainId,
    })

    if (!xdataToken) {
        throw new Error('Token contract not found.')
    }

    const xdata = await xdataToken.balanceOf(address)

    if (xdata.lt(amount)) {
        throw new Error('Unsufficient funds.')
    }

    const migrationStrategy = chainId === XDAI_CHAIN_ID ? migrateXdaiTokens : migrateMainnetTokens

    await migrationStrategy({
        chainId,
        provider,
        amount,
    })
}

export default function useConvert({ adapter, address, chainId, xdataAmountWei, onComplete }) {
    const [converting, setConverting] = useState(false)

    const busyRef = useRef(false)

    const propsRef = useRef({
        address: undefined,
        chainId: undefined,
        xdataAmountWei: undefined,
    })

    const scheduleConvert = useCallback(() => {
        if (busyRef.current) {
            return
        }
        busyRef.current = true

        setConverting(true)

        propsRef.current = {
            address,
            chainId,
            xdataAmountWei,
        }
    }, [address, chainId, xdataAmountWei])

    const isMounted = useIsMounted()

    const onCompleteRef = useRef(onComplete)

    onCompleteRef.current = onComplete

    useEffect(() => {
        if (!adapter) {
            return
        }

        if (!converting) {
            busyRef.current = false
            return
        }

        ;(async () => {
            try {
                const { current: props } = propsRef

                await migrate({
                    chainId: props.chainId,
                    address: props.address,
                    provider: adapter.getProvider(),
                    amount: props.xdataAmountWei,
                })

                if (typeof onCompleteRef.current === 'function') {
                    onCompleteRef.current()
                }
            } catch (e) {
                console.error('Conversion failure', e)

                if (typeof onCompleteRef.current === 'function') {
                    onCompleteRef.current(e)
                }
            } finally {
                if (isMounted()) {
                    setConverting(false)
                }
            }
        })()
    }, [converting, isMounted, adapter])

    return {
        convert: scheduleConvert,
        converting,
    }
}
