import React from 'react'
import styled from 'styled-components'
import { truncate } from '~utils/streams'

const Separator = styled.span`
    color: #a3a3a3;
    margin: 0 0.1em;
`

export default function Address({ value }) {
    const [start, end] = /^0x/i.test(value) ? truncate(value).split('...') : [value, null]

    return (
        <>
            {start}
            {!!end && (
                <>
                    <Separator>...</Separator>
                    {end}
                </>
            )}
        </>
    )
}
