import { useEffect, useReducer } from 'react'
import { parseEther } from 'ethers/lib/utils'
import useIsMounted from '~hooks/useIsMounted'
import { getPublicNodeProvider, getTokens } from '~utils/ethereum'

/**
 * 1 full token == 10^18 wei "atomic" integer units
 * full tokens = formatEther(wei amount)
 * wei amount = parseEther(full tokens)
 */

const initialState = {
    address: undefined,
    balances: undefined,
}

const SET_ADDRESS = 'set address'

const SET_BALANCES = 'set balances'

function reducer(state, action) {
    switch (action.type) {
        case SET_ADDRESS:
            return {
                ...initialState,
                address: action.payload,
            }
        case SET_BALANCES:
            return {
                ...state,
                balances: action.payload,
            }
        default:
            return state
    }
}

export const ZERO = parseEther('0')

export const ONE = parseEther('1')

export default function useBalances({ address: addressProp, chainId, cache = 0 }) {
    const [{ address, balances }, dispatch] = useReducer(reducer, {
        ...initialState,
        address: addressProp,
    })

    const isMounted = useIsMounted()

    useEffect(() => {
        dispatch({
            type: SET_ADDRESS,
            payload: addressProp,
        })
    }, [addressProp])

    // clear balances on chain change
    useEffect(() => {
        dispatch({
            type: SET_BALANCES,
            payload: undefined,
        })
    }, [chainId])

    useEffect(() => {
        ;(async () => {
            try {
                const provider = getPublicNodeProvider({
                    chainId,
                })
                const { dataToken, xdataToken } = getTokens({
                    chainId,
                })

                if (!address || !provider || !xdataToken || !dataToken) {
                    return
                }

                const data = await (async () => {
                    try {
                        return await dataToken.balanceOf(address)
                    } catch (e) {
                        console.error('useBalances', address, e)
                    }

                    return ZERO
                })()

                const xdata = await (async () => {
                    try {
                        return await xdataToken.balanceOf(address)
                    } catch (e) {
                        console.error('useBalances', address, e)
                    }

                    return ZERO
                })()

                const nativeCurrency = await (async () => {
                    try {
                        return await provider.getBalance(address)
                    } catch (e) {
                        console.error('useBalances', address, e)
                    }

                    return ZERO
                })()

                if (!isMounted()) {
                    return
                }

                dispatch({
                    type: SET_BALANCES,
                    payload: {
                        data,
                        nativeCurrency,
                        xdata,
                    },
                })
            } catch (e) {
                console.error('useBalances', address, e)
            }
        })()
    }, [address, chainId, isMounted, cache])

    return balances
}
