import React, { useRef, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { MqTablet } from 'streamr-ui/css/consts'
import Address from './Address'
import { Box, List, Item } from './Selector.styled'
import AlertIcon from './assets/AlertIcon.svg'
import CaretIcon from './assets/CaretIcon.svg'
import TickIcon from './assets/TickIcon.svg'
import useToggle from '~hooks/useToggle'
import NetworkIcon from '~shared/NetworkIcon'

const Caret = styled.div`
    position: relative;
    width: 16px;
    height: 16px;
    transition: 180ms transform ease-in-out;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 9px;
    }

    &[data-open='true'] {
        transform: rotate(180deg);
    }

    @media ${MqTablet} {
        width: 10px;
        height: 10px;

        img {
            width: 10px;
            height: 7px;
        }
    }
`

const Network = styled.div`
    position: relative;
    width: 20px;
    height: 20px;

    ${NetworkIcon} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
    }

    @media ${MqTablet} {
        width: 16px;
        height: 16px;
    }
`

const Tick = styled.div`
    position: relative;
    width: 56px;
    height: 64px;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
    }

    @media ${MqTablet} {
        width: 48px;

        img {
            width: 10px;
            height: 10px;
        }
    }
`

const AddressColumn = styled.div``
const BoxValue = styled.div``

const UnstyledSelector = ({ className, items, selected, onSelect, disabled }) => {
    const [open, toggle] = useToggle(false)

    const rootRef = useRef()

    useEffect(() => {
        const onGlobalClick = ({ target }) => {
            const { current: root } = rootRef

            if (!root || !root.contains(target)) {
                toggle(false)
            }
        }

        document.addEventListener('mousedown', onGlobalClick)

        return () => {
            document.removeEventListener('mousedown', onGlobalClick)
        }
    }, [toggle])

    const { title: selectedTitle } = useMemo(() => {
        const selectedItem = items.find(({ id }) => id === selected)

        return (
            selectedItem || {
                id: 0,
                title: 'Not supported',
            }
        )
    }, [items, selected])

    return (
        <div ref={rootRef} className={className} disabled={disabled}>
            <Box
                onClick={() => {
                    if (!disabled) {
                        toggle()
                    }
                }}
            >
                <Network>
                    <NetworkIcon chainId={selected} emptyIcon={AlertIcon} />
                </Network>
                <BoxValue>
                    <Address value={selectedTitle} />
                </BoxValue>
                <Caret data-open={open}>
                    <img src={CaretIcon} alt="" />
                </Caret>
            </Box>
            <List visible={open}>
                {items.map(({ id, title }) => (
                    <li key={id}>
                        <Item
                            onClick={() => {
                                if (typeof onSelect === 'function') {
                                    onSelect(id)
                                }
                                toggle(false)
                            }}
                        >
                            <Network>
                                <NetworkIcon chainId={id} />
                            </Network>
                            <AddressColumn>
                                <Address value={title} />
                            </AddressColumn>
                            <Tick>{selected === id && <img src={TickIcon} alt="" />}</Tick>
                        </Item>
                    </li>
                ))}
            </List>
        </div>
    )
}

const Selector = styled(UnstyledSelector)`
    color: #323232;
    flex-basis: 50%;
    overflow: visible;
    position: relative;
    transition: opacity 180ms ease-in-out;

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }

    ${BoxValue} {
        margin-left: 6px;
        margin-right: 6px;
        line-height: 20px;
        flex: 1;
        text-align: initial;
    }

    ${Item} {
        padding: 0;
        text-align: left;

        ${Network} {
            width: 64px;
            height: 64px;

            ${NetworkIcon} {
                width: 32px;
                height: 32px;
            }
        }

        ${AddressColumn} {
            flex: 1;
        }
    }

    @media ${MqTablet} {
        flex-basis: auto;
    }
`

export default Selector
